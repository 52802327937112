<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import ErrorLine from './Edit/ErrorLine.vue';

// Components
import Button from '@/Components/Button/Button.vue';
import ButtonSmall from '@/Components/Button/Small.vue';
import DataCard from '@/Components/DataCard.vue';
import DataTableLine from '@/Components/DataTableLine.vue';
import Money from '@/Components/Input/Money.vue';
import Number from '@/Components/Input/Number.vue';
import Table from '@/Components/Table/Table.vue';

// Partials
import StatusBar from '@/Components/Invoice/StatusBar.vue';
import Title from '@/Components/Title/Title.vue';

// Icons
import pdf from '@i/pdf-icon-white.svg';
import save from '@i/save-icon-white.svg';

const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    invoice: Object,
    vatRateOptions: Object,
});

const form = useForm({
    ...props.invoice,
    invoice_lines: props.invoice.invoice_lines.sort((invoice_line) => invoice_line.type == 'manual'),
});

const addEmptyLine = () => {
    form.invoice_lines.push({
        id: (Math.random() + 1).toString(36),
        type: 'manual',
        description: '',
        quantity: 1,
        unit_price: 0,
        vat_rate: 21,
    });
};
const removeLine = (i) => form.invoice_lines.splice(i, 1);

const tableSettings = [
    { label: t('Name'), width: '35%', cell: 'Bold' },
    { label: t('Quantity'), width: '15%' },
    { label: t('Unit price'), width: '15%' },
    { label: t('VAT'), width: '15%' },
    { label: t('Total'), width: '15%', cell: 'Bold' },
];

const tableData = computed(() =>
    form.invoice_lines
        .filter((line) => line.type != 'manual')
        .map((line) => {
            return {
                id: line.id,
                platformCosts: line.type,
                rowData: [
                    line.description,
                    line.quantity,
                    '€ ' + line.unit_price / 100,
                    line.vat_rate + '%',
                    '€ ' + line.amount,
                ],
            };
        })
);

const lengthCurrentLines = computed(() => tableData.value.length);

const newLines = computed(() => form.invoice_lines.filter((line) => line.type == 'manual'));

const sendForm = () => {
    form.put(route('staff.invoices.update', { invoice: props.invoice.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <StaffLayout :title="$t('Invoices')">
        <template #default>
            <Title :title="invoice.number"> </Title>

            <div class="flex items-end gap-8">
                <DataCard class="mt-6">
                    <p class="font-bold">{{ invoice.location.name }}</p>
                    <p>
                        {{ invoice.location.address.street_name }}
                        {{ invoice.location.address.street_number }}{{ invoice.location.address.street_number_addition
                        }}<br />
                        {{ invoice.location.address.postcode }},
                        {{ invoice.location.address.city }}
                    </p>
                </DataCard>

                <DataCard class="mt-6">
                    <p v-if="invoice.location.phone_number" class="m-0">{{ invoice.location.phone_number }}</p>
                    <p v-if="invoice.location.administration_email" class="m-0">
                        {{ invoice.location.administration_email }}
                    </p>
                </DataCard>
            </div>

            <StatusBar :invoice="invoice" />

            <Table :simpleLayout="true" :tableSettings="tableSettings" :data="tableData">
                <template #extraTableBody>
                    <tbody class="text-base text-blue" v-for="(newline, i) in newLines" :key="i">
                        <tr>
                            <td class="pr-6">
                                <FormKit
                                    type="text"
                                    name="description"
                                    v-model="
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].description
                                    "
                                />
                            </td>

                            <td class="pr-6">
                                <Number
                                    v-model:value="
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].quantity
                                    "
                                />
                            </td>

                            <td class="pr-6">
                                <Money
                                    v-model:value="
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].unit_price
                                    "
                                />
                            </td>

                            <td class="pr-6">
                                <FormKit
                                    type="select"
                                    select-icon="down"
                                    name="vat_rate"
                                    v-model="
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].vat_rate
                                    "
                                    :options="vatRateOptions"
                                />
                            </td>

                            <td class="relative py-2">
                                <Money
                                    disabled
                                    :value="
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].unit_price *
                                        form.invoice_lines[
                                            form.invoice_lines.findIndex((line) => line.id == newline.id)
                                        ].quantity
                                    "
                                    :valueSymbol="'€'"
                                />

                                <button
                                    class="absolute transition-opacity duration-300 -translate-y-1/2 top-1/2 right-4 hover:opacity-50"
                                    @click="removeLine(form.invoice_lines.findIndex((line) => line.id == newline.id))"
                                >
                                    <img src="/icon/remove.svg" alt="" />
                                </button>
                            </td>
                        </tr>
                        <ErrorLine
                            v-if="form.errors.invoice_lines"
                            :errors="form.errors.invoice_lines[i + lengthCurrentLines]"
                        />
                    </tbody>
                </template>
            </Table>

            <ButtonSmall @click="addEmptyLine" icon="/icon/add_48.svg" class="mt-2" id="add-line-button">
                <div class="flex items-center gap-2">
                    <div class="w-full">+ {{ $t('Add {attribute}', { attribute: $t('line') }) }}</div>
                </div>
            </ButtonSmall>

            <div class="self-end w-2/5 mt-6">
                <DataTableLine>
                    <p>{{ $t('Total ex. VAT') }}</p>
                    <span class="font-bold"> € {{ invoice.total }}</span>
                </DataTableLine>
                <DataTableLine>
                    <p>{{ $t('VAT') }}</p>
                    <span class="font-bold"> € {{ invoice.vat }}</span>
                </DataTableLine>
                <DataTableLine>
                    <p>{{ $t('Total incl. VAT') }}</p>
                    <span class="font-bold"> € {{ invoice.total_including_vat }}</span>
                </DataTableLine>
            </div>

            <div class="flex self-end gap-6 mt-10 text-base">
                <Button
                    orange
                    @click="sendForm"
                    :icon="save"
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Save')"
                    class="text-base w-fit min-h-10"
                />

                <Button
                    asA
                    orange
                    :icon="pdf"
                    :href="route('staff.invoices.download', { id: invoice.id })"
                    class="w-fit"
                    :text="$t('Download')"
                />
            </div>
        </template>
    </StaffLayout>
</template>
r
